@use '~@lululemon/ecom-pattern-library/lib/styles/includes.scss' as *;

.legalContainer {
  grid-column: span 4;
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  gap: rem(16);
  padding: rem(24) rem(16);
  border-top: rem(1) solid $grey25;

  @include mq($desktop-and-above) {
    padding: rem(24) 0 0 0;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
}

.legalLinksContainer {
  display: flex;
  flex-direction: column;
  gap: rem(16);

  @include mq($desktop-and-above) {
    flex-direction: row;
  }

  .legalLink {
    color: $grey100;

    span {
      margin: 0;
    }

    @include mq($desktop-and-above) {
      &:last-child {
        padding-left: rem(20);
        border-left: rem(1) solid $grey25;
      }
    }
  }
}

.copyright {
  color: $grey100;
  @include textBody3();
}
