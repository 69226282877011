@use '~@lululemon/ecom-pattern-library/lib/styles/includes.scss' as *;

.accountSidebarNavLinks {
  position: relative;
  display: flex;
  height: calc(100vh - rem(138));

  .sidebarNavLinksContainer {
    position: sticky;
    background-color: $white;
    top: 0;
    height: 100%;
    width: 0;
    align-self: flex-start;

    @include mq($desktop-and-above) {
      min-width: rem(214);
    }

    .sidebarNavLinksWrapper {
      display: none;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-top: rem(24);

      @include mq($desktop-and-above) {
        display: flex;
        padding-top: rem(40);
      }
    }
  }

  .accountUserDetails {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-y: auto;
    border: rem(1) solid $grey25;
    padding: rem(40);

    @include mq($tablet-and-above) {
      border-left: rem(1) solid $grey25;
    }

    @include mq($desktop-and-above) {
      align-items: flex-start;
    }
  }
}

.tabButtonLabelClassNames {
  text-decoration: none;
  @include textBody1();

  &.active {
    font-weight: $fontWeightSemibold;
  }

  &:hover {
    text-decoration: underline;
  }
}

.wrapperContainer {
  display: flex;
  gap: rem(16);
  justify-content: space-between;
  width: 100%;
  flex-direction: column;
}

.modalCancelButton {
  display: flex;
  justify-content: space-around;
  padding: 0;

  span {
    margin: 0;
  }
}
