@use '~@lululemon/ecom-pattern-library/lib/styles/includes.scss' as *;

.gridContainer {
  margin-bottom: rem(30);
  padding-top: rem(30);

  div:nth-child(2) {
    row-gap: rem(16);
  }
}

.ctaBlock {
  text-align: start;
  svg {
    display: none;
  }
}
.quickLinksHeadingLoading {
  height: rem(48);
  width: rem(200);
}
.quickLinksLoading {
  height: rem(95);
}
