@use '~@lululemon/ecom-pattern-library/lib/styles/includes.scss' as *;

.detailedNavOuter {
  transition: height 200ms ease-out;
  grid-area: detail;
  overflow: hidden;
  grid-column: span 12;
  row-gap: rem(32);
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: $white;
}
.showDetailedNav {
  display: grid;
}

.detailedNavInner,
.detailedNavActivities {
  width: 100%;
  display: grid;
  margin: 0 auto;
  max-width: rem(1372);
  grid-template-columns: rem(0) repeat(11, 1fr) rem(0);
  @include mq('min-width: 1080px') {
    grid-template-columns: rem(86) repeat(11, 1fr) 0;
  }
}
.detailedNavInner {
  row-gap: rem(32);
  padding: rem(24) rem(24) rem(0) rem(24);
  grid-template-areas: 'features maindetails promoblock';
}
.detailedNavActivities {
  padding: rem(8) rem(24) rem(8) rem(24);
  grid-template-areas: 'activitylabel activitieslist shopall';
}

.detailedNavRow,
.detailedNavActivitiesRow {
  width: 100%;
  &:last-child {
    margin-bottom: rem(32);
  }
}
.detailedNavActivitiesRow {
  background-color: $grey03;
}
