@use '~@lululemon/ecom-pattern-library/lib/styles/includes.scss' as *;

.form {
  flex: 1;
  margin: 0;
  position: relative;
  @include mq($desktop-and-above) {
    .searchIcon:hover + .input[type='search'] {
      border: rem(1.5) solid $black;
      color: $black;
      &::placeholder {
        color: $black;
      }
    }
    z-index: 2;
  }
}

.formWrapper {
  background-color: $white;
  z-index: 2;
}

.input[type='search'] {
  padding: 0 rem(32) 0 rem(40);
  background: $grey03;
  cursor: pointer;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border: 0;
  margin: 0;
  height: rem(48);
  width: 100%;
  min-width: rem(264);

  &:focus {
    outline: none;
  }

  &::-webkit-search-cancel-button {
    -webkit-appearance: none;
  }
  @include mq($desktop-and-above) {
    border-radius: rem(4);
    background: $white;
    border: rem(1.5) solid $black;
    color: $grey100;

    &:hover::placeholder {
      color: $black;
      opacity: 1;
    }

    &:hover:-ms-input-placeholder {
      color: $black;
    }

    &::placeholder {
      color: $grey100;
    }
    &:hover::placeholder {
      color: $black;
      opacity: 1;
    }

    &:focus {
      border: rem(1.5) solid $black;
      box-shadow: 0px 0px 0 1px rgba(0, 0, 0, 0.1);
      color: $black;
      &:hover::placeholder {
        color: $grey100;
      }
    }
    &:hover {
      border: rem(1.5) solid $black;
      color: $black;
      cursor: text;
    }
  }

  &::-webkit-search-decoration {
    display: none;
  }

  @include mq('min-width: 992px') {
    height: rem(50);
  }
}

.isOpen {
  position: relative;
  > form {
    z-index: 2;
    position: relative;
  }
}

.searchIcon {
  cursor: pointer;
  position: absolute;
  fill: $black;
  top: rem(17);
  left: rem(12);

  @include mq($desktop-and-above) {
    top: rem(18);
  }
  @include mq($mobile) {
    left: rem(16);
    top: rem(16);
  }
}

.closeIcon {
  cursor: pointer;
  position: absolute;
  fill: $black;
  top: rem(17);
  right: rem(12);
  visibility: hidden;
  z-index: 2;

  &.show {
    visibility: visible;
  }

  @include mq($desktop-and-above) {
    top: rem(18.5);
    right: rem(15);
  }
}

.fullScreen {
  @extend .searchBox;
  z-index: 105;

  &.isOpen {
    input[type='search'] {
      background-color: $white;
    }
  }

  @include mq($desktop-and-above) {
    z-index: auto;
  }
}

.mask {
  @include mq($desktop-and-above) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background: rgba(0, 0, 0, 0.5);
    &.delay {
      visibility: hidden;
      opacity: 0;
      transition: 150ms ease-out 250ms;
    }
    &.open {
      visibility: visible;
      opacity: 1;
      &.delay {
        transition: 0ms;
      }
    }
  }
}

/* a hack to apply global styles */
.searchBox {
  :global(.no-page-scroll) {
    @include mq($mobile) {
      overflow: hidden;
      position: fixed;
      width: 100%;
    }
  }
}
