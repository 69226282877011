@use '~@lululemon/ecom-pattern-library/lib/styles/includes.scss' as *;

.shippingAddressContainer {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: $white;
  padding: rem(24);
}

.title {
  @include textSmall();
  margin-bottom: rem(8);
}

.subtext {
  @include textBody2();
  margin-bottom: rem(24);
}

.shippingAddressContentSection {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-self: stretch;
}

.loader {
  height: rem(100);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
