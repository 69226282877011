@use '~@lululemon/ecom-pattern-library/lib/styles/includes.scss' as *;

.primaryContent {
  margin-bottom: rem(14);
  margin-top: rem(14);

  @include mq($desktop-and-above) {
    padding: 0;
  }

  .tableContainer {
    overflow-x: auto;
    overflow-y: hidden;
  }

  .table {
    text-align: center;
  }

  .tableColHeader {
    font-weight: 400;
  }

  .link {
    display: inline;
    padding-bottom: rem(2);
    position: relative;
    text-decoration: none;

    &::before {
      background-color: $grey65;
      bottom: 0;
      content: '';
      height: rem(2);
      left: 0;
      position: absolute;
      width: 100%;
    }

    &:hover {
      color: currentcolor;
      text-decoration: none;

      &::before {
        background-color: $red100;
      }
    }

    &::after {
      content: none;
    }
  }
}
