@use '~@lululemon/ecom-pattern-library/lib/styles/includes.scss' as *;

.mainDetailsWrapper {
  display: grid;
  grid-area: maindetails;
  grid-template-rows: 1fr;

  grid-template-columns: repeat(8, 1fr);
  grid-column: span 8;
  // Add grid-row-gap to account for edge cases with > 4 columns, causing wrapping
  grid-row-gap: rem(16);

  &:first-child {
    grid-column-start: 2;
    grid-column-end: 10;
    grid-template-columns: repeat(8, 1fr);
  }
}

.mainDetailsList {
  display: grid;
  grid-gap: rem(8);
  grid-auto-rows: min-content;
  grid-column: span 2;
  text-transform: capitalize;
}

.mainDetailsList li:first-child {
  margin-top: rem(32);
}

.mainDetailsCategory {
  @include textSubtitle();
}

.mainDetailsSubCategory {
  cursor: pointer;
  span {
    margin-top: 0;
    margin-left: 0;
  }
}
