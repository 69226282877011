@use '~@lululemon/ecom-pattern-library/lib/styles/includes.scss' as *;

@mixin labelUnderline {
  .underline {
    position: relative;
    &::after {
      background: $colorRedLululemon;
      bottom: rem(-5);
      content: '';
      display: block;
      height: rem(2);
      left: 0;
      position: absolute;
      right: 100%;
      top: initial;
      transition: right 250ms ease-in-out;
    }
  }

  &:hover,
  &:focus {
    .underline {
      &::after {
        right: 0;
      }
    }
  }
}

.bodyMask {
  top: rem(76);
  background: rgba($color: $black, $alpha: 0.4);
  position: absolute;
  height: 100vh;
  left: 0;
  bottom: 0;
  right: 0;
}

.popup {
  position: relative;
  height: fit-content;
  @include labelUnderline();

  .arrowUp {
    border-bottom: rem(11) solid rgba($color: $black, $alpha: 0.12);
    border-left: rem(14) solid transparent;
    border-right: rem(14) solid transparent;
    left: rem(-2);
    position: absolute;
    top: rem(-3);
    z-index: 1000;

    @include mq($tablet-and-above) {
      top: rem(-3);
      left: rem(-140);
    }
    @include mq($desktop-and-above) {
      left: rem(-140);
      top: rem(-3);
    }

    &::after {
      border-bottom: rem(11) solid $white;
      border-left: rem(14) solid transparent;
      border-right: rem(14) solid transparent;
      content: '';
      left: rem(-14);
      position: absolute;
      top: rem(1);
    }
  }

  .iconWrapper {
    cursor: pointer;
    white-space: nowrap;
    width: fit-content;
    display: flex;
    column-gap: rem(8);
  }

  .panel {
    animation: appearDropdown 0.15s ease-in-out;
    z-index: 310;
    position: absolute;
    top: 40px;
    right: -55px;
    border-radius: 2px;
    background-color: white;
  }
}
