@use '~@lululemon/ecom-pattern-library/lib/styles/includes.scss' as *;

.modal {
  padding: rem(24);
  border-radius: rem(8);
}

.modalContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modalHeader {
  padding-bottom: rem(8);
  @include textBody2();

  font-weight: $fontWeightSemibold;
  line-height: rem(22);
}

.content {
  text-align: center;
  @include textBody2();
  font-size: rem(16);
  padding-bottom: rem(24);
}

.button {
  width: 100%;
}

.hideCloseButton {
  visibility: hidden !important;
  position: absolute;
}
