@use '~@lululemon/ecom-pattern-library/lib/styles/includes.scss' as *;

.container {
  display: flex;
  justify-content: center;
}

.gridContainer {
  gap: rem(24);
  min-height: min-content;
  width: 100%;
  max-width: rem(1280);
}

.heading {
  grid-column: span 12;
  text-align: center;
}

.lastUpdatedDate {
  grid-column: span 12;
  @include textButton();
}

.contentContainer {
  grid-column: span 12;
}

.contentText {
  @include textBody2();
  p {
    padding-bottom: rem(16);
  }

  ul {
    list-style-type: disc;
    padding-left: rem(24);
  }

  li {
    padding-bottom: rem(16);
  }
}

.contentSection {
  margin-bottom: rem(24);
}

.sectionHeading {
  margin-bottom: rem(16);
}

.headerMargin {
  margin: rem(48) 0 0;
}
