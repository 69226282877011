@use '~@lululemon/ecom-pattern-library/lib/styles/includes.scss' as *;

.simpleHeaderContainer {
  display: flex;
  position: sticky;
  top: rem(210);
  left: rem(178);
  background: $white;
  justify-content: center;
  align-items: center;
}

.navWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: rem(1440);
  height: rem(78);
  padding: 0 rem(30);

  @include mq($desktop-and-above) {
    padding: 0 rem(60);
  }
}

.skipNav {
  background: rgba(255, 255, 255, 0.87);
  display: none;
  height: rem(1);
  left: rem(4);
  opacity: 0;
  padding: 0;
  position: absolute;
  text-align: center;
  text-transform: uppercase;
  top: rem(4);
  width: calc(100% - rem(8));
  z-index: -1;

  @include mq($desktop-and-above) {
    display: block;

    &:focus {
      height: calc(100% - rem(8));
      opacity: 1;
      padding: rem(28);
      z-index: 105;
    }
  }
}
