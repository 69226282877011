@use '~@lululemon/ecom-pattern-library/lib/styles/includes.scss' as *;

.container {
  background-color: $grey03;
  padding: rem(16) rem(8);
}

.subtext {
  @include textBody2();
  margin-bottom: rem(16);
}

.link {
  span {
    margin-left: 0;
    line-height: rem(20);
  }
}

.paymentTerm {
  @include textBody1();
  font-weight: $fontWeightMedium;
  margin-bottom: rem(8);
}

.paymentTermValue {
  @include textBody2();
  font-weight: $fontWeightSemibold;
  margin-bottom: rem(16);
}

.billingAddress {
  @include textBody1();
  font-weight: $fontWeightMedium;
  margin-bottom: rem(8);
}

.addressSubtext {
  @include textBody2();
  margin-bottom: rem(16);
}

.notification {
  margin-bottom: rem(16);
}
