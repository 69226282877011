@use '~@lululemon/ecom-pattern-library/lib/styles/includes.scss' as *;

.footer {
  background: $white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.gridContainer {
  display: none;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding: rem(24) rem(16);
  max-width: rem(1440);

  @include mq($desktop-and-above) {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding: rem(32) rem(60) rem(24) rem(60);
  }
}

.accordion {
  width: 100%;
  display: block;

  @include mq($desktop-and-above) {
    display: none;
  }
}

.accordionButton {
  @include textSubtitle();
  border-top: rem(1) solid $grey25;
  padding: rem(16);
}

.accordionIcon {
  color: $black;
}

.footerLinks {
  color: $grey100;

  span {
    margin: 0;
  }
}
