@use '~@lululemon/ecom-pattern-library/lib/styles/includes.scss' as *;

.loadingBox {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: rem(100);
}

.wrapper {
  box-sizing: border-box;
  margin: 0 auto;
  position: relative;
}

.containerFluid {
  margin-right: auto;
  margin-left: auto;
  padding-right: $gridGutterSmall * 2;
  padding-left: $gridGutterSmall * 2;
  padding-bottom: $gridGutterSmall * 2;

  @include mq($tablet-and-above) {
    padding-right: $gridGutterMedium * 2;
    padding-left: $gridGutterMedium * 2;
    padding-bottom: $gridGutterMedium * 2;
  }

  @include mq($desktop-and-above) {
    padding-right: $gridGutterLarge * 2;
    padding-left: $gridGutterLarge * 2;
    padding-bottom: $gridGutterLarge * 2;
  }
}

.loadingIndicator {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
