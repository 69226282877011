@use '~@lululemon/ecom-pattern-library/lib/styles/includes.scss' as *;

.container {
  width: 100%;
  background-color: $white;
  padding: rem(24);

  details {
    position: inherit;
  }
}

.contentContainer {
  position: relative;
}

.loading {
  min-height: rem(300);
}

.heading {
  @include textSmall();
  margin-bottom: rem(8);
}

.subtext {
  @include textBody2();
  margin-bottom: rem(16);
}

.accordion {
  overflow: visible;
}

.accordionHeading {
  @include textXSmall();
}

.accordionPanel {
  padding: 0;
}
