@use '~@lululemon/ecom-pattern-library/lib/styles/includes.scss' as *;

.siteSecondaryNav {
  display: block;
  grid-area: secondary;
  height: 100%;
  padding: rem(18) 0;
  z-index: 1;
}

.siteSecondaryList {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: flex-end;
  gap: rem(24);
}
