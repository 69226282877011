@use '~@lululemon/ecom-pattern-library/lib/styles/includes.scss' as *;

.carousel {
  display: flex;
  justify-content: center;
  padding-left: $gridGutterSmall;
  padding-right: $gridGutterSmall;

  @include mq($tablet-and-above) {
    padding-left: $gridGutterMedium;
    padding-right: $gridGutterMedium;
  }

  @include mq($desktop-and-above) {
    padding-left: $gridGutterLarge;
    padding-right: $gridGutterLarge;
  }

  > div {
    max-width: $gridMaxWidth;
  }
}

.carousel__card {
  > div > span > a > span:last-child {
    display: inline-flex;
  }
}
.loading {
  height: rem(500);
  width: 100%;
}
