@use '~@lululemon/ecom-pattern-library/lib/styles/includes.scss' as *;

.listContainer {
  display: grid;
  gap: rem(20);
  padding: rem(15) rem(16) rem(30) rem(16);

  @include mq($desktop-and-above) {
    gap: rem(18);
    padding: rem(24) 0;
  }
}

.header {
  display: none;
  @include textSubtitle();

  @include mq($desktop-and-above) {
    display: block;
  }
}

.footerLinks {
  color: $grey100;

  span {
    margin: 0;
    padding: 0;
  }
}
