@use '~@lululemon/ecom-pattern-library/lib/styles/includes.scss' as *;

.container {
  justify-items: center;
  gap: rem(32);
  margin: rem(48) 0;
  min-height: min-content;
}

.heading {
  grid-column: span 12;
  text-align: center;
}

.subheading {
  grid-column: span 12;
  text-align: center;
  @include textBody1();
}

.accordionContainer {
  grid-column: span 12;
  width: 100%;

  @include mq($desktop-and-above) {
    width: rem(872);
  }
}
