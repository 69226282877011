@use '~@lululemon/ecom-pattern-library/lib/styles/includes.scss' as *;

.bg {
  position: fixed;
  width: 200%;
  height: 200%;
  top: -50%;
  left: -50%;
  z-index: 0;
}

.bg img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  min-width: 50%;
  min-height: 50%;
}

.contentwrapper {
  min-height: rem(452);
  margin: 16% auto 0;
  text-align: center;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 0;
}

.contentwrapper .headline p {
  font-size: 76px;
  margin-bottom: 20px;
  margin-top: 15px;
  color: #ffffff;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
}

.contentwrapper .message {
  line-height: 0.7;
  font-size: 24px;
  margin-top: 15px;
  color: #ffffff;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  padding: 0 110px;
}
