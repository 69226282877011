@use '~@lululemon/ecom-pattern-library/lib/styles/includes.scss' as *;

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: rem(8);
}

.icon {
  position: relative;
  display: inline-block;
  line-height: 0;
  padding: rem(10) 0;

  @include mq($desktop-and-above) {
    // To avoid quickview from collapsing on the edge of the cart icon
    &:before {
      content: '';
      display: block;
      width: rem(10);
      position: absolute;
      height: rem(59);
      left: rem(34);
      top: 0;
      z-index: 1;
    }
  }
}

.count {
  @include textBody3();
}

.bagIndicator {
  &::after {
    animation: shadow-pulse 4s infinite;
    background-color: $red100;
    border-radius: 50%;
    content: '';
    display: block;
    height: rem(8);
    position: absolute;
    right: rem(-3);
    top: rem(7);
    width: rem(8);
  }
}

@keyframes shadow-pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.5);
  }
  50% {
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 1);
  }
  100% {
    box-shadow: 0 0 0 rem(8) rgba(0, 0, 0, 0);
  }
}
