@use '~@lululemon/ecom-pattern-library/lib/styles/includes.scss' as *;

.topNavDesktopBar {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin: 0 auto;
  max-width: rem(1372);
  padding: rem(4) rem(25);
  width: 100%;
  gap: 24px;

  &:before {
    background: $grey03;
    content: '';
    height: rem(34);
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    transition: background linear 100ms;
  }

  > * {
    &:not(:last-child) {
      margin-right: rem(24);
    }
  }
}
