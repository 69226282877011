@use '~@lululemon/ecom-pattern-library/lib/styles/includes.scss' as *;

.container {
  position: relative;
  width: 100%;
}

.input {
  @include textBody2();
  width: 100%;
  border: none;
  padding: 0;
}

.bold {
  font-weight: $fontWeightMedium;
}

.label {
  @include textBody1();
  font-weight: $fontWeightMedium;
  width: 100%;
  margin-bottom: rem(10);
  display: block;
}

.menuItem {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: rem(16);
  gap: rem(8);
}

.highlighted {
  background-color: $black;
  color: $white;
}

.inputBoxContainer {
  display: flex;
  height: rem(52);
  gap: rem(8);
  border-radius: rem(4);
  border: rem(1) solid $black;
  background-color: $white;
  padding: rem(14) rem(16);
  box-shadow: 0 rem(1) rem(2) 0 rgba($black, 0.05);
}

.menuItemContent {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.toggleButton {
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu {
  border: rem(1) solid $grey03;
  background-color: $white;
  border-radius: rem(4);
  box-shadow: 0 rem(4) rem(4) 0 rgba($black, 0.25);
  display: none;
  left: 0;
  list-style: none;
  max-height: rem(400);
  height: auto;
  overflow-y: auto;
  padding: 0;
  position: absolute;
  right: 0;
  top: 100%;
  z-index: 1;
}

.menuOpen {
  display: block;
}

.itemLabel {
  display: flex;
  flex-wrap: wrap;
}

.check {
  width: rem(16);
  height: rem(16);
}

.defaultPill {
  width: rem(92);
}

.closeIcon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.hide {
  display: none;
}

.carat {
  transform: rotate(180deg);
}

.noResultsContainer {
  @include textBody2();
  max-height: rem(250);
  display: flex;
  margin: rem(32);
  align-items: center;
  flex-direction: column;
}
