@use '~@lululemon/ecom-pattern-library/lib/styles/includes.scss' as *;
@use 'styles/mixins';

.featuresWrapper {
  display: block;
  grid-area: features;
  border-right: 1px solid $grey25;
  grid-column-start: 2;
  grid-column-end: 4;
  margin-right: rem(40);
}

.featuresList {
  display: grid;
  grid-area: list;
  grid-gap: rem(16);
  grid-auto-rows: min-content;
  padding-right: rem(8);
}

.l2 {
  cursor: pointer;
  @include textBody2();
  @include mixins.labelUnderline();
  > svg {
    color: $red100;
    margin-left: rem(8);
    transform: translateX(rem(0));
    transition: transform 250ms ease;
    vertical-align: text-bottom;
  }
  &:hover > svg {
    transform: translateX(rem(5));
  }
}

.linkContentWrapper span {
  margin-left: 0;
  margin-top: 0 !important;
  padding-bottom: 0 !important;
}

.linkText {
  @include textBody2();
}
