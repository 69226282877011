@use '~@lululemon/ecom-pattern-library/lib/styles/includes.scss' as *;

.notification {
  @include textBody2();
  width: 100%;
}

.link {
  margin-left: rem(8);
}
