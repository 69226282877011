@use '~@lululemon/ecom-pattern-library/lib/styles/includes.scss' as *;

@mixin labelUnderline {
  .underline {
    position: relative;
    &::after {
      background: $colorRedLululemon;
      bottom: rem(-5);
      content: '';
      display: block;
      height: rem(2);
      left: 0;
      position: absolute;
      right: 100%;
      top: initial;
      transition: right 250ms ease-in-out;
    }
  }

  &:hover,
  &:focus {
    .underline {
      &::after {
        right: 0;
      }
    }
  }
}

.accountMenu {
  background-color: $colorWhite;
  border-radius: rem(4);
  box-shadow: 0 0 rem(3) 0 rgba(0, 0, 0, 0.24);
  display: block;
  height: auto;
  left: 50%;
  letter-spacing: normal;
  padding: rem(24) rem(16) rem(24);
  position: absolute;
  right: 0;
  text-transform: initial;
  transform: translate(-50%, 0);
  top: rem(8);
  width: 90%;
  z-index: 106;

  @include mq($tablet-and-above) {
    left: auto;
    transform: none;
    width: rem(327);
    max-width: rem(327);
    right: rem(50);
    top: rem(8);
  }

  @include mq($desktop-and-above) {
    top: rem(8);
    right: rem(50);
    &:before {
      content: '';
      display: block;
      width: 100%;
      position: absolute;
      height: rem(4);
      left: 0;
      top: rem(-4);
      z-index: 2;
    }
  }

  .accountLink {
    @include textBody1();

    align-items: center;
    display: flex;
    padding: rem(16) rem(8) rem(16) 0;
    width: 100%;

    @include labelUnderline();
  }

  .closeIcon {
    cursor: pointer;
  }
}

.signinABAccountMenu {
  @include mq($desktop-and-above) {
    top: rem(47);
  }
  @include mq('max-width: 1099px') {
    right: rem(-30);
  }
}

.greetingBlock {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 0 rem(16);
}

.greetingMsg {
  @include textXSmall();

  margin: 0;
}

.listItem {
  border-top: rem(1) solid $colorDivideLineGrey;
  display: flex;
  cursor: pointer;
}

.lastListItem {
  border-bottom: rem(1) solid $colorDivideLineGrey;
}

.signOutLink {
  padding-top: rem(24);

  .accountLink {
    display: inline-block;
    padding: 0;
    @include primaryLink;
    @include textBody2();
    width: auto;
    &:hover,
    &:focus {
      .underline {
        &::after {
          display: none;
        }
      }
    }
  }
}

.rightChevron {
  margin-left: auto;
}
