@import '~@lululemon/ecom-pattern-library/lib/styles/includes.scss';

.errorWrapper {
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  height: calc(100vh - rem(346));
  align-items: center;
}

.errorContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  gap: rem(24);

  .messageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
    flex-direction: column;
    align-items: center;
    gap: rem(24);
    width: rem(350);

    .errorText {
      color: $black;

      @include mq($tablet-and-above) {
        border: none;
        margin-bottom: 0;
        text-align: center;
      }
    }

    .errorDesc {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: rem(24);
    }

    .errorTextP {
      text-align: center;
      font-size: rem(16);
      font-style: normal;
      font-weight: $fontWeightRegular;
      line-height: normal;
      width: rem(348);
    }

    .buttonSecondary {
      display: flex;
      width: rem(350);
      padding: rem(16);
      justify-content: center;
      align-items: center;
      gap: rem(5);
      border-radius: rem(4);
      background-color: $black;
    }

    .logoutLink {
      span {
        font-size: rem(16);
        margin-left: rem(5);
      }
    }
  }
}
