@use '~@lululemon/ecom-pattern-library/lib/styles/includes.scss' as *;

.cartLineItemContainer {
  display: flex;
  cursor: pointer;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: rem(8);

  &:first-of-type {
    margin-top: rem(12);
  }

  &:last-of-type {
    margin-bottom: rem(12);
  }
}

.cartItemProductDetails {
  flex: 1;
  display: flex;
  flex-direction: column;
  @include textBody2();

  .cartItemProductName {
    font-weight: $fontWeightMedium;
  }

  .cartQtyPriceRow {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 0 rem(8);
  }
}

.cartItemImage {
  height: rem(86);
  width: rem(72);
  position: relative;
  border: rem(1) solid $grey25;
  min-height: rem(86);
  min-width: rem(72);
  margin-right: rem(8);
}

.listPrice {
  color: $grey85;
  text-decoration-line: line-through;
}

.prices {
  display: flex;
  flex-direction: row-reverse;
  font-weight: $fontWeightMedium;
}
