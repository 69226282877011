@use '~@lululemon/ecom-pattern-library/lib/styles/includes.scss' as *;

.simpleFooterContainer {
  display: flex;
  align-items: flex-start;
  background: $white;
  justify-content: center;
}

.legalLinksContainer {
  display: grid;
  gap: rem(18);
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: rem(1440);
  padding: rem(24) rem(16);

  @include mq($tablet-and-above) {
    padding: rem(24) rem(25);
  }

  @include mq($desktop-and-above) {
    padding: rem(32) rem(60) rem(24) rem(60);
  }
}

.legalLink {
  color: $grey100;

  span {
    margin: 0;
  }
}

.copyright {
  color: $grey100;
  @include textBody3();
}
