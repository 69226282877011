@use '~@lululemon/ecom-pattern-library/lib/styles/includes.scss' as *;

.sizeGuideCategories {
  margin-top: rem(20);

  @include mq($tablet-and-above) {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin: rem(40) 0 0;
  }
}

.sizeGuideTabPanel {
  flex-grow: 3;

  @include mq($tablet-and-above) {
    width: 50%;
  }
}

.sizeGuideTab {
  flex: 1;
  margin: rem(7) rem(7) 0 0;

  @include mq($desktop-and-above) {
    margin: 0 rem(7) 0 0;
  }

  @include mq($desktop-and-above) {
    margin-bottom: rem(20);
  }
}

.sizeGuideVirtualShopCTA {
  margin-top: rem(20);

  @include mq($tablet-and-above) {
    flex: 1;
    margin-top: 0;
    min-width: rem(389);
  }
}

.sizeGuideCTAWrapper {
  align-items: stretch;
  display: inline-flex;
  flex-wrap: wrap;
  height: auto;
  justify-content: space-between;
  margin-top: rem(16);
  width: 100%;
}

.sizeGuideCTA {
  margin-bottom: rem(12);
  min-width: rem(350);
  width: 100%;

  img {
    max-height: rem(95);
    max-width: rem(95);
  }

  a {
    background-color: $white;
    display: flex;

    div {
      display: flex;
    }
  }

  @include mq($xlarge-and-above) {
    width: 48%;
  }
}

.primaryContentParagraph {
  margin: rem(14) 0;
}

.primaryContentHeading {
  margin-bottom: rem(24);
}
