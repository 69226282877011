@use '~@lululemon/ecom-pattern-library/lib/styles/includes.scss' as *;

.mainContainer {
  justify-items: center;
  gap: rem(24);
  margin: rem(24) 0;
  padding: 0 rem(50) rem(60);
  background: $grey03;

  @include mq($desktop-and-above) {
    max-width: rem(1440);
  }
}

.headingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: rem(24) auto;
}

.heading {
  grid-column: 2 / 12;
  @include textMedium();
}

.checkoutLoaderContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 75vh;
  grid-column: span 12;
}

.lhs {
  grid-column: 2 / 9;
  padding-bottom: 0;
  padding-right: rem(50);
  display: flex;
  flex-direction: column;
  gap: rem(8);
  width: 100%;
  position: relative;
}

.pageNotFound {
  grid-column: span 12;
}
