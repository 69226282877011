@use '~@lululemon/ecom-pattern-library/lib/styles/includes.scss' as *;

.carousel {
  display: flex;
  justify-content: center;

  > div {
    max-width: $gridMaxWidth;
  }
}

.carousel__card {
  > div > span > a > span:last-child {
    display: inline-flex;
  }
}
