@use '~@lululemon/ecom-pattern-library/lib/styles/includes.scss' as *;

.cartSummaryContainer {
  grid-column: 9 / 12;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.bagButton:focus,
.bagButton:focus-visible {
  outline: none;
}

.caratIconExpanded {
  transform: rotate(180deg);
}

.caratIconCollapsed {
  transform: rotate(0deg);
}

.cartBagQtyContainer {
  display: flex;
  width: 100%;
  @include textBody1();
  align-items: center;
  border-bottom: rem(1) solid $grey25;
  padding-bottom: rem(12);
  gap: rem(16);
  margin-top: rem(12);
}

.CartSummaryLineItem {
  display: flex;
  flex-direction: column;
  gap: rem(8);
}

.cartItemsContainer {
  display: flex;
  flex-direction: column;
  border-bottom: rem(1) solid $grey25;
  max-height: rem(475);
  overflow-y: auto;
}

.itemQuantity {
  display: flex;
  max-width: rem(220);
  word-break: break-all;
  text-transform: lowercase;
}
