@use '~@lululemon/ecom-pattern-library/lib/styles/includes.scss' as *;

.gridContainer {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: auto;
  gap: rem(10);

  a {
    max-height: rem(250);
    width: 100%;

    &:first-child {
      grid-row: 1 / span 3;
      max-height: rem(775);
      width: 100%;
    }
  }
  div:nth-child(2) {
    row-gap: rem(16);
  }
}
