@use '~@lululemon/ecom-pattern-library/lib/styles/includes.scss' as *;

.orderSummary {
  display: grid;
  grid-template-columns: 1fr 1fr auto;
  background-color: $white;
  gap: rem(18) 0;
  padding: rem(24);

  & > div:nth-last-child(n + 4) {
    padding-bottom: rem(18);
    border-bottom: rem(1) solid;
    border-radius: 0;
    border-color: $grey25;
  }
}

.paymentDetails {
  display: flex;
}

.icon {
  margin-right: rem(16);
  height: rem(24);
  width: rem(40);
}

.subText {
  @include textBody1();
}

.arIcon {
  @include textBody2();
  width: rem(40);
  height: rem(24);
  font-weight: $fontWeightSemibold;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: rem(4);
  background: $grey15;
  margin-right: rem(8);
}

.arTitle {
  @include textBody1();
  margin-bottom: rem(8);
}

.netAR {
  font-weight: $fontWeightMedium;
  margin-left: rem(5);
}

.address {
  @include textBody2();
}

.billingAddressTitle {
  margin-bottom: rem(4);
  font-weight: $fontWeightMedium;
}

.ptText {
  @include textBody1();
}
