@use '~@lululemon/ecom-pattern-library/lib/styles/includes.scss' as *;

$topNavigationHeight: rem(34);

.megaNav {
  position: sticky;
  top: -$topNavigationHeight;
  z-index: 104;
  transition: opacity 0.5s 0.4s ease, transform 0.4s ease-in-out;
}

.mainNav::after {
  content: '';
  display: block;
  position: fixed;
  opacity: 0;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  pointer-events: none;
  background: rgba(0, 0, 0, 0.4);
  z-index: -1;
  transition: opacity 250ms ease-in-out;
  transition-delay: 100ms;
}

.megaNav.open {
  .mainNav::after {
    opacity: 1;
  }
}

.mainNav {
  background: $white;

  @include mq($desktop-and-above) {
    transition: background linear 100ms;
  }
}

.topNavWrapperDesktop {
  display: flex;
  position: relative;
}

.navContainerDesktop {
  background: $white;
  position: relative;
}

.navWrapperDesktop {
  display: grid;
  grid-area: desktop;
  grid-template-areas: 'logo primary secondary';
  grid-template-columns: rem(86) auto 1fr;
  grid-template-rows: rem(76) minmax(0, auto);
  width: 100%;
  max-width: rem(1372);
  align-items: center;
  margin: 0 auto;
  padding: 0 rem(25);
}

.isSticky {
  position: fixed;
  top: 0;
  z-index: 103;
  background: $white;
  width: 100%;
}

.logo {
  display: flex;
  grid-area: logo;
  align-items: center;
  justify-content: flex-start;
}

.skipNav {
  background: rgba(255, 255, 255, 0.87);
  display: none;
  height: rem(1);
  left: rem(4);
  opacity: 0;
  padding: 0;
  position: absolute;
  text-align: center;
  text-transform: uppercase;
  top: rem(4);
  width: calc(100% - rem(8));
  z-index: -1;

  @include mq($desktop-and-above) {
    display: block;

    &:focus {
      height: calc(100% - rem(8));
      opacity: 1;
      padding: rem(28);
      z-index: 105;
    }
  }
}
