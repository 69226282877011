@import '~@lululemon/ecom-pattern-library/lib/styles/includes.scss';

.emptyCartContainer {
  display: flex;
  flex-flow: column;
  align-items: center;
  height: 100vh;

  @include mq($small-and-above) {
    justify-content: center;
  }
  @media only screen and (max-width: $small) {
    justify-content: center;
    padding: rem(30) 0;
  }

  .headingBlock {
    margin-bottom: rem(24);
    @include textSmall();
  }
}
