@use '~@lululemon/ecom-pattern-library/lib/styles/includes.scss' as *;

.modalContainer {
  display: flex;
  flex-direction: column;
  gap: rem(16);

  label {
    margin: 0;
  }
}

.numberContainerParentClass {
  display: flex;
  position: relative;
}

.numberContainerClassName {
  display: flex;
  flex-direction: col;
  background-color: $white;
  background-position: center right rem(16);
  background-repeat: no-repeat;
  border: rem(1) solid $black;
  border-radius: rem(4);
  display: block;
  font-size: rem(18);
  height: rem(52);
  padding: rem(14) rem(16);
  width: 100%;
}

.ccImageIcon {
  position: absolute;
  z-index: 100;
  justify-content: flex-end;
  top: rem(15);
  right: rem(10);
  cursor: pointer;
}

.cvvContainer {
  background-color: $white;
  background-position: center right rem(16);
  background-repeat: no-repeat;
  border: rem(1) solid $black;
  border-radius: rem(4);
  display: block;
  font-size: rem(18);
  height: rem(52);
  padding: rem(14) rem(16);
}

.btnContainer {
  width: rem(208);
}

.errorMessage {
  color: $red90;
}

.ModelFieldLabel {
  color: $black;
  font-size: rem(18);
  font-weight: $fontWeightRegular;
  line-height: rem(24);
}

.cardsWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: rem(8);
}

.flexLabelGroup {
  display: flex;
  flex-direction: column;
  gap: rem(8);
}

.cardsContainer {
  display: flex;
  flex-direction: column;
  gap: rem(24);
}

.addCreditCardLoader {
  display: flex;
  height: rem(50);
  align-items: center;
  justify-content: center;
}

.validationErrorMessage {
  line-height: 1;
  color: $red90;
}

.contentContainer {
  position: relative;
}

.loading {
  min-height: rem(300);
}
