@use '~@lululemon/ecom-pattern-library/lib/styles/includes.scss' as *;

.container {
  background-color: $grey03;
  padding: rem(16) rem(8);
}

.subtext {
  @include textBody1();
  font-weight: $fontWeightMedium;
}

.addCard {
  margin-top: rem(8);
}

.subtitle {
  @include textBody2();
}

.notification {
  margin-bottom: rem(16);
}
