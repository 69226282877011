@use '~@lululemon/ecom-pattern-library/lib/styles/includes.scss' as *;

.shippingMethod {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: rem(24);
  padding: rem(24);
  width: 100%;
  background: $white;
}

.loaderContainer {
  height: rem(160);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.shippingMethodContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: rem(16);
}

.errorMessage {
  span {
    padding: 0 0 rem(1) 0;
    margin-left: 0;
    margin-top: 0 !important;
  }
}
