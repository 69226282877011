@use '~@lululemon/ecom-pattern-library/lib/styles/includes.scss' as *;

.orderReferenceContainer {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: $white;
  width: 100%;
  padding: rem(24);

  .accordianItem {
    > :nth-child(n + 1) {
      padding: 0;
    }
  }
}

.heading {
  @include textXSmall();
  font-weight: $fontWeightSemibold;
}

.optional {
  color: $grey100;
  margin-left: rem(8);
}

.description {
  margin: rem(16) 0;
}

.inputField {
  max-width: rem(450);
  width: 100%;
}
