@use '~@lululemon/ecom-pattern-library/lib/styles/includes.scss' as *;

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: rem(16);

  & > div {
    border-bottom: rem(1) solid $grey25;
    padding: rem(16) 0;
    width: 100%;
  }
}

.cardContainer {
  @include textBody1();
  display: grid;
  grid-template-columns: repeat(2, auto) rem(66) 1fr auto;
  align-items: center;
  justify-content: flex-start;
}

.radioLabel.radioLabel.radioLabel {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.radionIcon.radionIcon {
  top: 0;
}

.icon {
  width: rem(28);
  height: fit-content;
  margin-right: rem(6);
}

.expired {
  color: $red90;
}

.billingAddressTitle {
  @include textBody1();
  margin: rem(16) 0 rem(8);
  font-weight: $fontWeightMedium;
}

.address {
  @include textBody2();
}

.red {
  color: $red90;
}
