@use '~@lululemon/ecom-pattern-library/lib/styles/includes.scss' as *;

.activityLabel {
  @include textSubtitle();
  text-transform: uppercase;
  background-color: $grey03;
  display: grid;
  grid-area: activitylabel;
  border-right: 1px solid $grey25;
  grid-column-start: 2;
  grid-column-end: 4;
  margin-right: rem(40);
  padding-block: rem(8);
  align-content: center;
}

.activitiesList {
  background-color: $grey03;
  padding-block: rem(8);
  align-content: center;
  display: grid;
  grid-area: activitieslist;
  grid-template-rows: 1fr;

  grid-template-columns: repeat(6, min-content);
  gap: 4.25rem;
  grid-column: span 7;
}

.activityLink {
  white-space: nowrap;
  display: inline-block;
  cursor: pointer;
  span {
    margin-top: 0;
    margin-left: 0;
  }
}

.shopAll {
  padding-block: rem(8);
  align-content: center;
  justify-content: end;
  display: grid;
  grid-area: shopall;
  grid-column-start: 11;
  grid-column-end: 13;
}

.shopAllLink {
  @include textSubtitle();
  text-transform: uppercase;
}
