@use '~@lululemon/ecom-pattern-library/lib/styles/includes.scss' as *;

@mixin labelUnderline {
  .underline {
    position: relative;
    &::after {
      background: $red100;
      bottom: rem(-5);
      content: '';
      display: block;
      height: rem(2);
      left: 0;
      position: absolute;
      right: 100%;
      top: initial;
      transition: right 250ms ease-in-out;
    }
  }

  &:hover,
  &:focus {
    .underline {
      &::after {
        right: 0;
      }
    }
  }
}
