@use '~@lululemon/ecom-pattern-library/lib/styles/includes.scss' as *;

.cartSummaryContainer {
  min-height: rem(576);
  display: flex;
  flex-direction: column;

  .cartBagQtyContainer {
    @include textBody1();
    display: flex;
    align-items: center;
    border-bottom: rem(1) solid $grey25;
    padding-bottom: rem(12);
    margin-top: rem(12);
    gap: rem(8);
  }

  .cartItemsContainer {
    border-bottom: rem(1) solid $grey25;
    margin: rem(12) 0;
    max-height: rem(480);
    overflow-y: auto;
  }
}

.orderReview {
  display: flex;
  align-self: flex-end;
  background-color: $white;
  @include textSmall();
  color: $grey85;
  padding: rem(24);
  margin-bottom: rem(8);
}

.ctaWrapperContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-end;
  gap: rem(8);
}

.additionalContentContainer {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: baseline;
  align-self: flex-start;
  margin-left: rem(24);
  gap: rem(4) 0;
}

.termsText {
  @include textBody2();
}

.termsAndConditionsError {
  font-size: rem(15);
  grid-column: span 2;
  margin-left: rem(24);
}

.nextStepButton {
  min-width: rem(355);
}

.privacypolicy {
  color: $grey100;
}

.ctaButtonSteps {
  color: $grey100;
  @include textBody2();
  padding: rem(2) 0 rem(16);
}
