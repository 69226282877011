@use '~@lululemon/ecom-pattern-library/lib/styles/includes.scss' as *;

.heading {
  @include textXSmall();
  display: flex;
  gap: rem(8);
  padding-right: rem(5);
}

.content {
  @include textBody2();
  padding-right: rem(10);
}

.changeStepButton {
  @include textBody2();
  span {
    line-height: 1;
  }
}
