@use '~@lululemon/ecom-pattern-library/lib/styles/includes.scss' as *;

.container {
  @include mq($desktop-and-above) {
    min-height: rem(452);
  }
}

.gridContainer {
  gap: rem(32);
  padding: rem(48) 0;
  justify-items: center;
}

.heading {
  grid-column: span 12;
  @include textLarge();
}

.subheadingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  grid-column: span 12;
  gap: rem(8);
}
