@use '~@lululemon/ecom-pattern-library/lib/styles/includes.scss' as *;

.paymentSummaryContainer {
  display: flex;
  flex-direction: column;
}

.paymentItemsContainer {
  display: flex;
  flex-direction: column;
  gap: rem(12);
  padding-top: rem(12);
}

.paymentItem {
  @include textBody2();
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.orderTotal {
  @include textBody1();
  border-top: rem(1) solid $grey25;
  padding-top: rem(12);
  font-weight: $fontWeightMedium;
}

.promoCodeContainer {
  margin-top: rem(24);
}

.promoContainer {
  display: flex;
  justify-content: space-between;
  gap: rem(8);
}

.appliedPromoCode {
  @include textBody2();
  margin-bottom: rem(2);
}

.promotionRemoved {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: rem(1.92);

  button {
    span {
      line-height: 1;
    }
  }
}

.promoCodeValue {
  @include textBody3();
  width: 100%;
  word-break: break-all;
}

.applyButton {
  height: rem(52);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: rem(32);
}

.inputContainer {
  position: relative;
  flex: 1;
}

.errorBorder {
  border-color: $red90;

  &:focus {
    border-color: $red90;
  }
}

.closeIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: rem(50);
  right: 0;

  &.hide {
    display: none;
  }

  &.error {
    color: $red90;
  }
}

.notification {
  @include textBody1();
}
