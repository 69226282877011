@use '~@lululemon/ecom-pattern-library/lib/styles/includes.scss' as *;

$navOffset: 20;

.sitePrimaryNav {
  font-size: rem(15);
  font-weight: 600;
  grid-area: primary;
  letter-spacing: rem(1.2);
  line-height: rem(20);
  position: relative;
  transition: color linear 100ms;

  @include mq('min-width: 1120px') {
    font-size: rem(16);
  }
}

.primaryNavList {
  display: flex;
  margin-left: rem(-($navOffset / 2));

  @include mq('min-width: 1120px') {
    margin-left: rem(-($navOffset));
  }
}

.underline {
  position: relative;
  &:after {
    display: block;
    position: absolute;
    content: '';
    height: rem(2);
    bottom: rem(-5);
    top: initial;
    left: 0;
    right: 100%;
    background: $red100;
    transition: right 250ms ease-in-out;
  }

  &:hover {
    &:after {
      right: 0;
    }
  }
}

.anchor {
  text-decoration: none;
  color: inherit;
  display: inline-block;
  &:hover {
    color: unset;
  }
}

.primaryNavAnchor {
  text-transform: uppercase;
  padding: rem(5) rem($navOffset / 2);
  cursor: pointer;

  &:after {
    transition: width 250ms ease-in-out;
    height: rem(4);
    left: rem($navOffset / 2);
    right: auto;
    width: 0;
  }

  &:hover,
  &.primaryNavAnchorActive {
    &:after {
      right: auto;
      width: rem(24);
    }
  }

  @include mq('min-width: 1120px') {
    padding: rem(5) rem($navOffset);
    &:after {
      left: rem($navOffset);
      right: auto;
      width: 0;
    }

    &:hover,
    &.primaryNavAnchorActive {
      &:after {
        right: auto;
        width: rem(24);
      }
    }
  }
}
